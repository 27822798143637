import React, { FC, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useUserContext } from 'common/UserProvider';
import useMetriCool from 'common/useMetriCool';
import { FooterTheme } from 'common/context';
import { FooterStyle, IPosition } from 'common/types';
import WalletManager from 'common/wallet';
import {
  USER_LOCAL_STORAGE_KEY,
  COMMUNITY_SECTION_ID,
	FAQ_SECTION_ID,
	JOIN_BETA_SECTION_ID,
	MOKENS_UNIVERSE_SECTION_ID,
	THE_MOKENS_SECTION_ID,
  MARKETPLACE_SECTION_ID
} from 'common/const';

import SEO from '@layout/SEO';
import Header from '@layout/Header';
import Footer from '@layout/Footer';
import { H2, H4, H5, HP } from '@global/Texts';
import JobOffer from '@global/JobOffer';
import ExternalLink from '@global/ExternalLink';

import metaImgPath from 'images/meta-img.png';

import './styles.scss';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Careers: FC = () => {
	const { t } = useTranslation();
	const { user, setUser, walletManager } = useUserContext();
	useMetriCool();

  const links = [
    { title: 'layout.mokens', url: `/#${THE_MOKENS_SECTION_ID}` },
    { title: 'layout.marketplace', url: `/#${MARKETPLACE_SECTION_ID}`},
    { title: 'layout.joinBeta', url: `/#${JOIN_BETA_SECTION_ID}` },
    { title: 'layout.mokensUniverse', url: `/#${MOKENS_UNIVERSE_SECTION_ID}`},
    { title: 'layout.community', url: `/#${COMMUNITY_SECTION_ID}`},
    { title: 'layout.faq', url: `/#${FAQ_SECTION_ID}`}
  ];

	const positions: IPosition[] = [
		{
			labelId: 'development',
			title: 'Web Developer - Backend',
			description:
				'We currently seek a talented, experienced web developer with experience and focus on the Back End side of the development cycle to help develop and expand our web platform that includes a web3 marketplace to trade and sell NFTs.',
			type: 'Barcelona, ES - Partially remote',
			dedication: 'Full-time',
			link: 'https://drive.google.com/file/d/1_riYv-77aFjHgDBlc-7KMgBmBpJVHyyt/view?usp=share_link',
		},
		{
			labelId: 'development',
			title: 'Web Developer - Frontend',
			description:
				'We currently seek a talented, experienced web developer with experience and focus on the Front End side of the development cycle to help develop and expand our web platform that includes a web3 marketplace to trade and sell NFTs.',
			type: 'Barcelona, ES - Partially remote',
			dedication: 'Full-time',
			link: 'https://drive.google.com/file/d/1uBs8yBB42XsQwbo3I7EQSZaOC9RwEQWX/view?usp=share_link',
		},
		{
			labelId: 'development',
			title: 'Unity Game Developer',
			description:
				'You will be responsible for implementing game functionality, translating design ideas, concepts, and requirements into a functional and engaging game.',
			type: 'Barcelona, ES - Partially remote',
			dedication: 'Full-time',
			link: 'https://drive.google.com/file/d/1x_5xI_8qr4-GNOffIqiPcjLIzXaThyRz/view?usp=share_link',
		},
		{
			labelId: 'development',
			title: 'Senior 3D Game Development Artist',
			description:
				'We are looking for a senior and talented 3D Artist to help us develop the Mokens (our 3D characters) and everything the Mokens League universe requires. You should have experience developing 3D characters and assets for multiple platforms such as PC, Android and iOS.',
			type: 'Barcelona, ES - Partially remote',
			dedication: 'Full-time',
			link: 'https://drive.google.com/file/d/1OhzXK98gknrvT5M5EMKIptz5dN25OPi2/view?usp=share_link',
		},
		{
			labelId: 'development',
			title: 'Blockchain Developer',
			description:
				'We are looking for a highly capable blockchain developer to design, implement, and distribute a secure blockchain-based network. You will be analyzing our blockchain needs, designing customized blockchain technologies, and launching and maintaining our blockchain network.',
			type: 'Barcelona, ES - Partially remote',
			dedication: 'Full-time',
			link: 'https://drive.google.com/file/d/1RFlQUTZEk76Di5lR4EbdAU4Rj5GS5SGi/view?usp=share_link',
		},
	];

	// TODO: Improve WalletManager to handle connection site-wide
	// instead of re-instantiated on each page.
	const init = async () => {
		try {
			if (walletManager.isConfigured()) {
				let signedUpUser = null;
				if (!user) signedUpUser = await walletManager.getSignedUpUser();
				if (signedUpUser) {
					setUser(signedUpUser);
					localStorage.setItem(
						USER_LOCAL_STORAGE_KEY + '_EMAIL',
						signedUpUser.userEmail
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang="en"
				imgPath={metaImgPath}
				imgAlt=""
				meta={[]}
			/>
			<main className="careers">
				<div className=" text-white">
					<div className="xxl:container mx-auto">
						<Header />

						<div className="px-4">
							<div className="max-w-3xl mx-auto pt-32 xl:pt-20">
								<H5 className="text-yellow">{t('careers.preTitle')}</H5>
								<H2 className="mb-2">{t('careers.title')}</H2>
								<HP className="mb-2">{t('careers.description')}</HP>
								<p className="text-base italic">
									{t('careers.subdescription.firstParagraph')}
								</p>
								<p className="text-base italic">
									{t('careers.subdescription.secondParagraph')}
								</p>
							</div>
							<div className="max-w-3xl mx-auto mt-12">
								<H4 className="mb-4">{t('careers.openings')}</H4>

								{positions.map((position, index) => (
									<JobOffer key={index} {...position} />
								))}
								<div className="mt-16 mb-24">
									<H4 className="mb-2">{t('careers.banner.title')}</H4>
									<p className="mb-2 text-base italic">
										{t('careers.banner.subtitle')}
									</p>
									<ExternalLink
										label="careers.banner.link"
										link={`mailto:${process.env.GATSBY_CAREERS_EMAIL}`}
									/>
								</div>
							</div>
						</div>
					</div>
					<FooterTheme.Provider value={FooterStyle.DARK}>
						<Footer links={links} />
					</FooterTheme.Provider>
				</div>
			</main>
		</>
	);
};

export default Careers;
