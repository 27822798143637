import React, { FC } from "react";

import { IIconLabelProps } from "common/types";

const IconLabel: FC<IIconLabelProps> = ({ label, icon }) => {
  return (
    <div className="flex items-center">
      <img src={icon} alt={label} className="w-4 mr-2.5" />
      <p className="italic">{label}</p>
    </div>
  );
};

export default IconLabel;
