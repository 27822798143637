import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IExternalLinkProps } from "common/types";

import { H5 } from "@global/Texts";

import external from "images/external.svg";

const ExternalLink: FC<IExternalLinkProps> = ({ link, label, className }) => {
  const { t } = useTranslation();
  return (
    <a className={className} href={link}>
      <H5 className="flex text-yellow tracking-normal">
        {t(label)}
        <img src={external} alt="external" className="w-3 ml-3.5" />
      </H5>
    </a>
  );
};

export default ExternalLink;
