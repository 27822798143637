interface ILabelProps {
  color: string;
  text: string;
}

export const getLabel = (label: string): ILabelProps => {
  switch (label) {
    case "design":
      return {
        color: "text-orange",
        text: "Design",
      };
    case "development":
      return {
        color: "text-pink",
        text: "Software development",
      };
    case "sales":
      return {
        color: "text-blue",
        text: "Sales",
      };
    default:
      return {
        color: "text-black",
        text: "Open Position",
      };
  }
};
