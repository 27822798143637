import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { getLabel } from "./utils";
import { IJobOfferProps } from "common/types";

import IconLabel from "@global/IconLabel";
import { H4, H6 } from "@global/Texts";
import ExternalLink from "@global/ExternalLink";

import location from "images/location.svg";
import time from "images/time.svg";

const JobOffer: FC<IJobOfferProps> = ({
  labelId,
  title,
  description,
  type,
  dedication,
  link,
}) => {
  const { t } = useTranslation();
  const fullLabel = getLabel(labelId);
  return (
    <div className="border rounded p-6 mb-6 relative border-gray-mineShaft">
      <H6 className={fullLabel.color}>{fullLabel.text}</H6>
      <H4 className="mb-1">{title}</H4>
      <p className="mb-4">{description}</p>
      <div className="flex space-x-6">
        <IconLabel label={type} icon={location} />
        <IconLabel label={dedication} icon={time} />
      </div>
      <ExternalLink
        label="careers.apply"
        link={link}
        className="absolute top-6 right-6"
      />
    </div>
  );
};

export default JobOffer;
